import { observer } from "mobx-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import useStore from "../../../hooks/useStore";
import {
  CHARTS_LABEL,
  STATIC_GROUPS,
  TARGET_LABEL,
  REVENUE_LABELS,
  GOVERNANCE_STATIC_GROUPS,
  REVENUE_FIELDS,
} from "../../../constants/constants";
import { toJS } from "mobx";
import {
  calculateMaxWithIncrease,
  getRoundedValue,
} from "../../../utils/helpers";
import ColumnChart from "../../../components/charts/columnChart";
import ChartImportButton from "./chartImportButton";
import DonutChart from "../../../components/charts/donutChart";
import BarChart from "../../../components/charts/barChart";
import GroupStackedChart from "../../../components/charts/groupStackedChart";
import { filterData } from "../../../helpers/groupDataCalculations";
import RadialBar from "../../../components/charts/radialBar";
import RadialBarEmission from "../../../components/charts/radialBarEmission";
import NewPieChart from "../../../components/charts/newPieChart";
import GradientDonutChart from "../../../components/charts/gradientDonutChart";
import BasicBarChart from "../../../components/charts/basicBarChart";
import ColumnChartRotatedLabels from "../../../components/charts/ColumnChartRotatedLabels";
import MultiRadialChart from "../../../components/charts/mulitRadialChart";
const Charts = ({ editor, year }) => {
  const [show, setShow] = useState(false);
  // const handleClose = useCallback(() => setShow(false), []);
  // const handleOpen = useCallback(() => setShow((p) => !p), []);
  const handleOpen = useCallback(() => setShow(true), []);
  const handleClose = useCallback(
    (e) => {
      if (
        e.target.closest(".chart-modal-content") === null ||
        (e.target.closest(".btn") === null &&
          e.target.closest(".chart-modal-overlay") !== null)
      ) {
        setShow(false);
      }
    },
    [setShow]
  );
  return (
    <>
      <div className="d-flex justify-content-sm-between">
        <button className="btn btn-primary" onClick={handleOpen}>
          <i className="mdi mdi-plus"></i> Import Charts
        </button>
      </div>
      {show && (
        <div
          className="chart-modal-overlay"
          onClick={handleClose}
          style={{
            cursor: "pointer",
          }}
        >
          <div className="chart-modal-content">
            <div
              style={{
                position: "relative",
              }}
            >
              <h4>Charts</h4>
              <span
                style={{
                  paddingTop: "16px",
                  fontWeight: 900,
                  fontSize: "16px",
                  position: "absolute",
                  cursor: "pointer",
                  right: 20,
                  zIndex: 100,
                  top: -8,
                }}
                onClick={handleClose}
              >
                X
              </span>
              <ESGDashbaord editor={editor} year={year}/>
              <WaterCharts editor={editor} year={year} />
              <WasteCharts editor={editor} year={year}/>
              <EnergyCharts editor={editor} year={year} />
              <EmissionCharts editor={editor} year={year}/>
              {/* <BiodiversityCharts editor={editor} /> */}
              <RevenueCharts editor={editor} year={year} />
              <MonthlyParameter editor={editor} year={year} />
              {/* <OhcChart editor={editor} /> */}
              <CustomerAndPrivacyChart editor={editor} year={year}/>
              <GovernanceChart editor={editor} year={year} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const ESGDashbaord = observer(({ editor, year}) => {
  //  
  const {
    governance: { fetchDashboard, dashboard },
    social: { fetchChartData, chartData },
    companyStore: {
      companyUnit,
      fetchRevenueData,
      revenuePageData,
      companyData,
    },
    dashboardStore: { showAll, fetchDashboardDetails, dashboardDetails },
  } = useStore();
  const company = toJS(companyData);
  const categoryCheck =
    company?.assignCategory?.map(({ catIdAssignCategory: { name } }) => name) ||
    [];
  const specificFieldsForPieChart = useMemo(
    () => ["Existence and effectiveness of a risk management framework."],
    []
  );
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    fetchDashboard({ year, companyUnit });
    fetchChartData({ year, companyUnit });
    fetchRevenueData({ year, companyUnit });
    fetchDashboardDetails({ signal, year, companyUnit });
  }, [
    fetchDashboard,
    fetchChartData,
    year,
    companyUnit,
    showAll,
    fetchRevenueData,
    fetchDashboardDetails,
  ]);
  const getGovernanceDonutChartData = useCallback(
    ({ group, year }) => {
      const current = dashboard?.[Number(year).toString()]?.[group] || [];
      const data =
        current
          ?.filter((ele) => !specificFieldsForPieChart.includes(ele.fieldName))
          ?.sort((a, b) => Number(a.value) - Number(b.value)) // sort by value
          ?.map((ele) => Number(ele.value) || 0) || [];
      const labels =
        current
          ?.filter((ele) => !specificFieldsForPieChart.includes(ele.fieldName))
          ?.sort((a, b) => Number(a.value) - Number(b.value)) // sort by value
          ?.map((ele) => ele.fieldName) || [];
      return { data, labels };
    },
    [dashboard, specificFieldsForPieChart]
  );
  const barChartData = useMemo(() => {
    const tempData = dashboardDetails?.charts?.aqi || {}; // Ensure tempData is always an object

    // Initialize arrays to hold series and categories
    const seriesData = [];
    const categories = [];

    // Mapping through emission scopes (like A1, B1, etc.)
    ["A1", "B1"].forEach((group) => {
      const groupDataForYear =
        tempData?.[year]?.reduce((acc, curr) => ({ ...acc, ...curr }), {}) ||
        {};

      // Calculate data for each group (handling C1 and C2 specially)
      const dataValue =
        group === "C"
          ? (groupDataForYear["C1"]?.prevValue || 0) +
            (groupDataForYear["C2"]?.prevValue || 0)
          : groupDataForYear[group]?.prevValue || 0;

      // Push name and data for this group
      categories.push(CHARTS_LABEL.energy[group]); // Add the label to categories
      seriesData.push(dataValue); // Add the data to series
    });

    // Handling additional data like Scope3 and totalGhgEmissionFinished
    if (tempData?.Scope3) {
      categories.push("Scope 3"); // Add Scope 3 to categories
      seriesData.push(tempData.Scope3?.data?.[1] || 0); // Add Scope 3 value
    }

    if (tempData?.totalGhgEmissionData) {
      categories.push("Total GHG Emission Emission"); // Add Total GHG Emission to categories
      seriesData.push(tempData.totalGhgEmissionData?.data?.[1] || 0); // Add Total GHG emission value
    }

    return { series: [{ name: "", data: seriesData }], categories };
  }, [dashboardDetails?.charts?.aqi, year]);

  const getWaterIntenityChartData = useCallback(
    (type, year) => {
      const fieldData = revenuePageData?.fieldData || [];
      const currentYear = Number(year).toString();
      const currentYearData = fieldData.filter(
        (data) => data.type === type && data.year === currentYear
      );
      // Create maps with the title as keys to match the field.valKey (if needed)
      const currentYearMap = currentYearData.reduce((acc, data) => {
        acc[data.title] = data;
        return acc;
      }, {});

      // Map REVENUE_FIELDS to the chart format
      return REVENUE_FIELDS.filter((field) => field.type === type).map(
        (field) => {
          const dataValues = [
            Number(currentYearMap[field.label]?.value.toFixed(0)) || 0,
          ];
          const maxValues = [
            (Number(currentYearMap[field.label]?.value.toFixed(0)) || 0) * 1.2,
          ];
          const unitName =
            currentYearMap[field.label]?.unitName || "";

          return {
            name: `${field.legend} : ${dataValues}`,
            data: dataValues,
            max: maxValues,
            unitName: unitName,
          };
        }
      );
    },
    [revenuePageData?.fieldData]
  );

  const getRevenueUnit = (year) => {
    const revenueData =
      revenuePageData?.revenue?.find(
        (e) => e.type === "all" && e.year === (year).toString()
      ) || {};
    return revenueData?.unit?.shortName || " "; // Get the short name of the unit for the selected year
  };

  const socialPieChartData = useMemo(() => {
    // Assuming chartData contains the monthly parameter data for different years
    const data =
      chartData?.filter((e) => e.kpiName === "Monthly Parameter") || [];

    if (!year) return { dataSeries: [], labels: [] }; // Return empty if no year is passed

    const results = {
      Male: {
        label: "Male",
        sum: 0,
      },
      Female: {
        label: "Female",
        sum: 0,
      },
    };

    // Define months from April 2024 to March 2025 for the financial year
    const months = [
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
      "January",
      "February",
      "March",
    ];

    data.forEach((item) => {
      const value = item.value || 0; // Assume there's a value field to sum

      // Check if the item falls within the selected financial year
      const itemMonthIndex = months.indexOf(item.month); // Get the month index
      const isWithinFinancialYear = itemMonthIndex !== -1; // Ensure the month is valid (within the financial year)

      // Adjust the year comparison depending on the month (April to March)
      const expectedYear = itemMonthIndex < 9 ? Number(year) : Number(year) + 1; // April to December (selected year), January to March (next year)

      if (isWithinFinancialYear && item.year === expectedYear) {
        if (item.fieldGroup === "A_A_A") {
          results.Male.sum += value; // Sum values for Male
        } else if (item.fieldGroup === "A_A_B") {
          results.Female.sum += value; // Sum values for Female
        }
      }
    });

    // Return the sums in a format suitable for the NewPieChart component
    return {
      dataSeries: [results.Male.sum, results.Female.sum],
      labels: [
        `${results.Male.label} : ${results.Male.sum}`,
        `${results.Female.label} :${results.Female.sum}`,
      ],
    };
  }, [chartData, year]);

  const socialMonthlyChartData = useMemo(() => {
    const data =
      chartData?.filter((e) => e.kpiName === "Monthly Parameter") || [];

    if (year) {
      // Define months from April 2024 to March 2025
      const months = [
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
        "January",
        "February",
        "March",
      ];

      return months.map((month, index) => {
        const currentYear = index < 9 ? Number(year) : Number(year) + 1; // For January to March, use `year + 1`

        const work =
          data.find(
            (item) =>
              item.fieldGroup === "C_A" &&
              item.month === month &&
              item.year === currentYear &&
              item.fieldName === "The number of hours worked."
          ) || {};

        const ltifr =
          data.find(
            (item) =>
              item.fieldGroup === "C_A" &&
              item.month === month &&
              item.year === currentYear &&
              item.fieldName === "LTIFR"
          ) || {};

        return {
          categories: month,
          value: work?.value
            ? (((ltifr?.value || 0) * 1000000) / work?.value).toFixed(2)
            : 0,
          year: currentYear,
        };
      });
    }
    return data;
  }, [chartData, year]);
  return (
    <>
      <div className=" d-flex  gap-5  flex-column">
        <div className="m-2">
          <h2 className="text-muted">
            ESG 
          </h2>
        </div>
        {/* governance multi-radial chart */}
        <div
          className="p-4 border chart-pop  rounded "
          id="editor-MultiRadialChart-gov-1"
        >
          <div className="d-flex justify-content-end">
            <ChartImportButton editor={editor} id="editor-MultiRadialChart-gov-1" />
          </div>
          <MultiRadialChart
            series={
              getGovernanceDonutChartData({ group: "A", year }).data
            }
            labels={
              getGovernanceDonutChartData({ group: "A", year })
                .labels || []
            }
            heading="Board Composition and diversity"
            position = "right"
            download={false}
          />
        </div>
        {/* emission bar chart */}
        <div
          className="p-4 border chart-pop  rounded "
          id="editor-BasicBarChart-emission-1"
        >
          <div className="d-flex justify-content-end">
            <ChartImportButton editor={editor} id="editor-BasicBarChart-emission-1" />
          </div>
          <BasicBarChart
            series={barChartData.series}
            categories={barChartData.categories}
            unit={dashboardDetails?.charts?.aqi?.unit || "MT"}
            heading="GHG"
            download={false}
          />
        </div>
        {/* emission intensity */}
        <div
          className="p-4 border chart-pop  rounded "
          id="editor-RadialBarEmission-emission-1"
        >
          <div className="d-flex justify-content-end">
            <ChartImportButton editor={editor} id="editor-RadialBarEmission-emission-1" />
          </div>
          <RadialBarEmission
            series={
              (
                dashboardDetails?.charts?.energy
                  ?.emissionIntensity?.value * 0.825
              ).toFixed(0) || [0]
            } // Extracting the first value directly
            labels={[
              `${
                dashboardDetails?.charts?.energy
                  ?.emissionIntensity?.label || "Intensity"
              }: ${
                (
                  dashboardDetails?.charts?.energy
                    ?.emissionIntensity?.value * 0.825
                ).toFixed(0) || 0
              }`,
            ]} // Formatted label
            unit={"MT"}
            max={
              (
                dashboardDetails?.charts?.energy
                  ?.emissionIntensity?.value * 0.825
              ).toFixed(0) * 1.2 || 0
            }
            heading="Emission Intensity"
            position = "right"
            download={false}
          />
        </div>
        {/* water intensity */}
        <div
          className="p-4 border chart-pop  rounded "
          id="editor-RadialBar-water-1"
        >
          <div className="d-flex justify-content-end">
            <ChartImportButton editor={editor} id="editor-RadialBar-water-1" />
          </div>
          <RadialBar
            series={
              getWaterIntenityChartData("water", year)[0]
                ?.data[0] || 0
            } // Extracting the first value directly
            labels={[
              `Water Intensity : ${
                getWaterIntenityChartData("water", year)[0]
                  ?.data[0] || 0
              }`,
            ]} // Make sure labels is an array
            max={
              getWaterIntenityChartData("water", year)[0]?.max[0] *
                1.2 || 0
            }
            unit={getWaterIntenityChartData("water", year)[0]?.unitName ? `${getWaterIntenityChartData("water", year)[0]?.unitName} / ${getRevenueUnit(year)}` : ""}
            position = "right"
            heading="Water Intensity"
            download={false}
          />
        </div>
        {/* social pie-chart intensity */}
        <div
          className="p-4 border chart-pop  rounded "
          id="editor-NewPieChart-social-1"
        >
          <div className="d-flex justify-content-end">
            <ChartImportButton editor={editor} id="editor-NewPieChart-social-1" />
          </div>
          <NewPieChart
            dataSeries={socialPieChartData.dataSeries || 0}
            labels={socialPieChartData.labels || ["Male", "Female"]}
            heading="Gender Diversity"
            position = "right"
            download={false}
          />
        </div>
        {/* social Safety performance intensity */}
        <div
          className="p-4 border chart-pop  rounded "
          id="editor-ColumnChartRotatedLabels-social-1"
        >
          <div className="d-flex justify-content-end">
            <ChartImportButton editor={editor} id="editor-ColumnChartRotatedLabels-social-1" />
          </div>
          <ColumnChartRotatedLabels
            series={[
              {
                name: "LTIFR",
                data: socialMonthlyChartData.map((item) => item.value), // Values for Y-axis
              },
            ]}
            categories={socialMonthlyChartData.map(
              (item) => item.categories
            )}
            heading="Safety Performance"
            download={false}
          />
        </div>

        {/* social Safety performance intensity */}
        <div
          className="p-4 border chart-pop  rounded "
          id="editor-GradientDonutChart-energy-1"
        >
          <div className="d-flex justify-content-end">
            <ChartImportButton editor={editor} id="editor-GradientDonutChart-energy-1" />
          </div>
          <GradientDonutChart
            data={
              dashboardDetails?.charts?.energy?.doughnutChart.map(
                (ele) => getRoundedValue(ele.value)
              ) || [0]
            }
            labels={
              dashboardDetails?.charts?.energy?.doughnutChart.map(
                (ele) => ele.label
              ) || ["Energy"]
            }
            unit={dashboardDetails?.charts?.energy?.unit || "kwh"}
            heading="Energy"
            position = "right"
            download={false}
          />
        </div>
      </div>
    </>
  );
});
const WaterCharts = observer(({ editor, year }) => {
  const {
    dashboardStore: { fetchDashboardDetails, dashboardDetails },
    companyStore: { fetchFinancialData, permissibleLimit, companyData },
  } = useStore();
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    fetchFinancialData({ year });
    fetchDashboardDetails({
      signal,
      year,
      all: true,
    });
    return () => {
      abortController.abort();
    };
  }, [fetchDashboardDetails, fetchFinancialData, year]);

  const columnChartData = useMemo(() => {
    const tempData = dashboardDetails?.charts?.water;
    const result = ["A", "B", "C"].map((group) => {
      return {
        name: STATIC_GROUPS.water[group],
        data: [year - 1, year]?.map(
          (year) =>
            tempData?.[year]?.reduce((acc, curr) => ({ ...acc, ...curr }), {})[
              group
            ]?.prevValue || 0
        ),
        target: [year - 1, year]?.map(
          (year) =>
            tempData?.[year]?.reduce((acc, curr) => ({ ...acc, ...curr }), {})[
              group
            ]?.target || 0
        ),
      };
    });
    return result;
  }, [dashboardDetails?.charts?.water, year]);

  const assignedKpi = toJS(companyData)?.assignKpi?.find(
    (e) => e.kpiIdAssignKpi.name === "Water"
  );

  const limits = permissibleLimit?.filter(
    ({ kpiId, subCatId, catId, year: tempYear }) =>
      kpiId === assignedKpi?.kpiId &&
      catId === assignedKpi?.catId &&
      subCatId === assignedKpi?.subCatId &&
      tempYear == year
  );
  const doughNutChartData = useMemo(() => {
    const tempData = dashboardDetails?.charts?.water?.[year];
    const result = ["A", "B", "C"].map(
      (group) =>
        tempData?.reduce((acc, curr) => ({ ...acc, ...curr }), {})?.[group]
          ?.prevValue || 0
    );
    const labels = ["A", "B", "C"].map((group) => STATIC_GROUPS.water[group]);
    return { data: result || [], labels };
  }, [dashboardDetails?.charts?.water, year]);

  const [maxLimitValue, setMaxLimitValue] = useState(0);
  useEffect(() => {
    let allValues = [
      ...(limits?.map((ele) => ele.value) || []),
      ...["A", "B", "C"].flatMap((group) =>
        [year - 1, year].map(
          (year) =>
            dashboardDetails?.charts?.water?.[year]?.reduce(
              (acc, curr) => ({ ...acc, ...curr }),
              {}
            )[group]?.prevValue || 0
        )
      ),
    ];
    setMaxLimitValue(Math.max(0, ...allValues) * 1.1);
  }, [dashboardDetails?.charts?.water, limits, year]);
  return (
    <>
      <div className=" d-flex  gap-5  flex-column">
      <div className="m-2">
        <h2 className="text-muted">
          ENVIRONMENT
        </h2>
      </div>

        <div
          className="p-4 border chart-pop  rounded "
          id="editor-chart-water-1"
        >
          <div className="d-flex justify-content-end">
            <ChartImportButton editor={editor} id="editor-chart-water-1" />
          </div>
          <ColumnChart
            max={maxLimitValue}
            data={
              columnChartData?.map(({ data, name }) => ({
                data,
                name,
              })) || []
            }
            unit={dashboardDetails?.charts?.water?.unit || "KL"}
            categories={[
              `FY${(year - 1).toString().slice(2)}`,
              `FY${year.toString().slice(2)}`,
            ]}
            yaxis={
              limits?.map((ele) => ({
                y: ele.value || 0, // Target value
                borderColor: "#FF4560",
                label: {
                  borderColor: "#FF4560",
                  style: {
                    color: "#fff",
                    background: "#FF4560",
                  },
                  text: `${TARGET_LABEL.water[ele.group]}: ${ele.value} ${
                    ele.unitIdMaster.short_name
                  }`,
                },
              })) || []
            }
            download={false}
            heading= "Water"
          />
        </div>

        <div
          id="editor-chart-water-2"
          className="p-4 border chart-pop  rounded "
        >
          <div className="d-flex justify-content-end">
            <ChartImportButton editor={editor} id="editor-chart-water-2" />
          </div>
          <DonutChart
            data={doughNutChartData.data || []}
            labels={doughNutChartData.labels || []}
            position="right"
            download={false}
            heading= "Water"
            unit ={dashboardDetails?.charts?.water?.unit || "KL"}
          />
        </div>
      </div>
    </>
  );
});
const WasteCharts = observer(({ editor, year }) => {
  const {
    dashboardStore: { dashboardDetails },
    companyStore: { permissibleLimit, companyData },
  } = useStore();
  const columnChartData = useMemo(() => {
    const tempData = dashboardDetails?.charts?.waste;
    const result = ["A", "D", "E"].map((group) => {
      return {
        name: CHARTS_LABEL.waste[group],
        data: [year - 1, year]
          ?.map(
            (year) =>
              tempData?.[year]?.reduce(
                (acc, curr) => ({ ...acc, ...curr }),
                {}
              )[group]?.prevValue || 0
          )
          ?.map((e) => getRoundedValue(e)),
        target: [year - 1, year]
          ?.map(
            (year) =>
              tempData?.[year]?.reduce(
                (acc, curr) => ({ ...acc, ...curr }),
                {}
              )[group]?.target || 0
          )
          ?.map((e) => getRoundedValue(e)),
      };
    });
    return result;
  }, [dashboardDetails?.charts?.waste, year]);

  const doughNutChartData = useMemo(() => {
    const tempData = dashboardDetails?.charts?.waste?.[year];
    const result = ["A", "D", "E"].map(
      (group) =>
        tempData?.reduce((acc, curr) => ({ ...acc, ...curr }), {})?.[group]
          ?.prevValue || 0
    );
    const labels = ["A", "D", "E"].map((group) => CHARTS_LABEL.waste[group]);
    return { data: result || [], labels };
  }, [dashboardDetails?.charts?.waste, year]);

  const assignedKpi = toJS(companyData)?.assignKpi?.find(
    (e) => e.kpiIdAssignKpi.name === "Waste"
  );

  const limits = permissibleLimit?.filter(
    ({ kpiId, subCatId, catId, year: tempYear }) =>
      kpiId === assignedKpi?.kpiId &&
      catId === assignedKpi?.catId &&
      subCatId === assignedKpi?.subCatId &&
      year == tempYear
  );

  const [maxLimitValue, setMaxLimitValue] = useState(0);
  useEffect(() => {
    let allValues = [
      ...(limits?.map((ele) => ele.value) || []),
      ...["A", "D", "E"].flatMap((group) =>
        [year - 1, year].map(
          (year) =>
            dashboardDetails?.charts?.waste?.[year]?.reduce(
              (acc, curr) => ({ ...acc, ...curr }),
              {}
            )[group]?.prevValue || 0
        )
      ),
    ];
    setMaxLimitValue(Math.max(0, ...allValues) * 1.1);
  }, [dashboardDetails?.charts?.waste, limits, year]);

  const barChartsGroupData = useMemo(() => {
    const tempData = dashboardDetails?.charts?.waste;
    const result = ["B", "C"].map((group) => {
      const data = [year - 1, year].map((year) => {
        const temp = tempData?.[year]?.reduce(
          (acc, curr) => ({ ...acc, ...curr }),
          {}
        );
        if (group === "B") {
          return ["B1", "B2", "B3"].reduce(
            (acc, curr) => acc + (temp?.[curr]?.prevValue || 0),
            0
          );
        }
        if (group === "C") {
          return ["C1", "C2"].reduce(
            (acc, curr) => acc + (temp?.[curr]?.prevValue || 0),
            0
          );
        }
        return temp?.[group]?.prevValue || 0;
      });
      return {
        label: STATIC_GROUPS.waste[group],
        data,
      };
    });
    return result;
  }, [dashboardDetails?.charts?.waste, year]);
  return (
    <>
      <div className=" d-flex  gap-5 mt-5  flex-column">
        <div
          id="editor-chart-waste-1"
          className="p-4 border chart-pop  rounded "
        >
          <div className="d-flex justify-content-end">
            <ChartImportButton editor={editor} id="editor-chart-waste-1" />
          </div>
          <ColumnChart
            max={maxLimitValue}
            data={
              columnChartData?.map(({ data, name }) => ({
                data: data.map((e) => getRoundedValue(e, 0)),
                name,
              })) || []
            }
            unit={dashboardDetails?.charts?.waste?.unit}
            categories={[
              `FY${(year - 1).toString().slice(2)}`,
              `FY${year.toString().slice(2)}`,
            ]}
            yaxis={
              limits?.map((ele) => ({
                y: getRoundedValue(ele.value) || 0, // Target value
                borderColor: "#FF4560",
                label: {
                  borderColor: "#FF4560",
                  style: {
                    color: "#fff",
                    background: "#FF4560",
                  },
                  text: `${TARGET_LABEL.waste[ele.group]}: ${ele.value} ${
                    ele.unitIdMaster.short_name
                  }`,
                },
              })) || []
            }
            download={false}
            heading= "Waste"
          />
        </div>

        <div
          id="editor-chart-waste-2"
          className="p-4 border chart-pop  rounded "
        >
          <div className="d-flex justify-content-end">
            <ChartImportButton editor={editor} id="editor-chart-waste-2" />
          </div>
          <DonutChart
            data={doughNutChartData.data || []}
            labels={doughNutChartData.labels || []}
            position="right"
            download={false}
            heading= "Waste"
            unit ={dashboardDetails?.charts?.waste?.unit || "KL"}
          />
          
        </div>
        <div className="row">
          {barChartsGroupData.length !== 0 &&
            barChartsGroupData?.map?.(({ label, data }, index) => (
              <div
                id={`editor-barchart-waste-${index}`}
                className="p-4 border chart-pop  rounded col-xl-6"
              >
                <div className="d-flex justify-content-end">
                  <ChartImportButton
                    editor={editor}
                    id={`editor-barchart-waste-${index}`}
                  />
                </div>
                <BarChart
                  series={[{ data, name: label }] || []}
                  categories={[
                    `FY${(year - 1).toString().slice(2)}`,
                    `FY${year.toString().slice(2)}`,
                  ]}
                  unit={dashboardDetails?.charts?.waste?.unit}
                  labels={label}
                  download={false}
                />
              </div>
            ))}
        </div>
      </div>
    </>
  );
});
const EnergyCharts = observer(({ editor, year }) => {   
  const {
    dashboardStore: { dashboardDetails },
  } = useStore();

  return (
    <>
      <div className=" d-flex  gap-5 mt-5  flex-column">
        <div
          id="editor-chart-energy-1"
          className="p-4 border chart-pop  rounded "
        >
          <div className="d-flex justify-content-end">
            <ChartImportButton editor={editor} id="editor-chart-energy-1" />
          </div>
          <ColumnChart
            categories={[
              `FY${(year - 1).toString().slice(2)}`,
              `FY${year.toString().slice(2)}`,
            ]}
            unit={dashboardDetails?.charts?.energy?.unit}
            data={dashboardDetails?.charts?.energy?.columnChart}
            yaxis={[]}
            download={false}
            heading= "Energy"
          />
        </div>

        <div
          id="editor-chart-energy-2"
          className="p-4 border chart-pop  rounded "
        >
          <div className="d-flex justify-content-end">
            <ChartImportButton editor={editor} id="editor-chart-energy-2" />
          </div>
          <DonutChart
            data={
              dashboardDetails?.charts?.energy?.doughnutChart.map((ele) =>
                getRoundedValue(ele.value)
              ) || []
            }
            labels={
              dashboardDetails?.charts?.energy?.doughnutChart.map(
                (ele) => ele.label
              ) || []
            }
            unit={dashboardDetails?.charts?.energy?.unit}
            position="right"
            download={false}
            heading= "Energy"
          />
        </div>

        <div
          id="editor-renewableBarChart-energy-1"
          className="p-4 border chart-pop  rounded "
        >
          <div className="d-flex justify-content-end">
            <ChartImportButton
              editor={editor}
              id="editor-renewableBarChart-energy-1"
            />
          </div>
          <DonutChart
            data={
              dashboardDetails?.charts?.energy?.renewableBarChart.map((ele) =>
                getRoundedValue(ele.value)
              ) || []
            }
            labels={
              dashboardDetails?.charts?.energy?.renewableBarChart.map(
                (ele) => ele.label
              ) || []
            }
            unit={dashboardDetails?.charts?.energy?.unit}
            position="right"
            download={false}
            heading= "Renewable"

          />
        </div>
        <div
          id="editor-nonRenewableBarChart-energy-1"
          className="p-4 border chart-pop  rounded "
        >
          <div className="d-flex justify-content-end">
            <ChartImportButton
              editor={editor}
              id="editor-nonRenewableBarChart-energy-1"
            />
          </div>
          <div className="donut">
            <DonutChart
              data={
                dashboardDetails?.charts?.energy?.nonRenewableBarChart?.map(
                  (ele) => ele.value
                ) || []
              }
              unit={dashboardDetails?.charts?.energy?.unit}
              labels={
                dashboardDetails?.charts?.energy?.nonRenewableBarChart?.map(
                  (ele) => ele.label
                ) || []
              }
              position="right"
              download={false}
              heading= "Non-Renewable"

            />
          </div>
        </div>
      </div>
    </>
  );
});
const EmissionCharts = observer(({ editor, year }) => {
  const {
    dashboardStore: { dashboardDetails },
    companyStore: { revenuePageData },
  } = useStore();
  const columnChartData = useMemo(() => {
    const tempData = dashboardDetails?.charts?.aqi;
    const result = ["A1", "B1"].map((group) => {
      return {
        name: CHARTS_LABEL.energy[group],
        data: [year - 1, year]?.map((year) =>
          group === "C"
            ? tempData?.[year]?.reduce(
                (acc, curr) => ({ ...acc, ...curr }),
                {}
              )["C1"]?.prevValue ||
              0 +
                tempData?.[year]?.reduce(
                  (acc, curr) => ({ ...acc, ...curr }),
                  {}
                )["C2"]?.prevValue ||
              0
            : tempData?.[year]?.reduce(
                (acc, curr) => ({ ...acc, ...curr }),
                {}
              )[group]?.prevValue || 0
        ),
        target: [year - 1, year]?.map((year) =>
          group === "C"
            ? tempData?.[year]?.reduce(
                (acc, curr) => ({ ...acc, ...curr }),
                {}
              )["C1"]?.prevValue ||
              0 +
                tempData?.[year]?.reduce(
                  (acc, curr) => ({ ...acc, ...curr }),
                  {}
                )["C2"]?.prevValue ||
              0
            : tempData?.[year]?.reduce(
                (acc, curr) => ({ ...acc, ...curr }),
                {}
              )[group]?.prevValue || 0
        ),
      };
    });
    // if (tempData?.totalGhgEmission) {
    //   result.push(tempData.totalGhgEmission);
    // }
    if (tempData?.Scope3) {
      result.push(tempData.Scope3);
    }
    if (tempData?.totalGhgEmissionFinished) {
      result.push(tempData.totalGhgEmissionFinished);
    }
    return result;
  }, [dashboardDetails?.charts?.aqi, year]);

  const doughNutChartData = useMemo(() => {
    const tempData = dashboardDetails?.charts?.aqi?.[year];
    const result = ["A1", "B1"].map((group) =>
      group === "C"
        ? tempData?.reduce((acc, curr) => ({ ...acc, ...curr }), {})?.["C1"]
            ?.prevValue ||
          0 +
            tempData?.reduce((acc, curr) => ({ ...acc, ...curr }), {})?.["C2"]
              ?.prevValue ||
          0 + tempData?.["Scope3"]?.data[0] ||
          0
        : tempData?.reduce((acc, curr) => ({ ...acc, ...curr }), {})?.[group]
            ?.prevValue || 0
    );
    const labels = ["A1", "B1"].map((group) => CHARTS_LABEL.energy[group]);
    // if (dashboardDetails?.charts?.aqi?.ghgEmissionIntensity) {
    //   result.push(dashboardDetails?.charts?.aqi?.ghgEmissionIntensity?.data[1]);
    //   labels.push("GHG Emission Intensity/Revenue");
    // }
    //  if (dashboardDetails?.charts?.aqi?.totalGhgEmissionFinished) {
    //   result.push(dashboardDetails?.charts?.aqi?.totalGhgEmissionFinished?.data[1]);
    //   labels.push("Total GHG Emission Intensity/Finished goods quantity produced");
    // }
    if (dashboardDetails?.charts?.aqi?.Scope3) {
      result.push(dashboardDetails?.charts?.aqi?.Scope3?.data[1]);
      labels.push("Scope 3 Emission");
    }

    return { data: result || [], labels };
  }, [dashboardDetails?.charts?.aqi, year]);

  const barChartsGroupData = useMemo(() => {
    const tempData = dashboardDetails?.charts?.aqi;
    const result = ["A1", "B1"].map((group) => {
      const data = [year - 1, year].map((year) => {
        const temp = tempData?.[year]?.reduce(
          (acc, curr) => ({ ...acc, ...curr }),
          {}
        );
        // if (group === "C") {
        //   return temp?.["C1"]?.prevValue || 0 + temp?.["C2"]?.prevValue || 0;
        // }
        return temp?.[group]?.prevValue || 0;
      });

      return {
        label: STATIC_GROUPS.aqi[group],
        data,
      };
    });

    const totalGhgEmission = {
      label: "GHG Emission",
      data: [
        result.reduce((acc, curr) => acc + curr.data[0], 0),
        result.reduce((acc, curr) => acc + curr.data[1], 0),
      ],
    };
    result.push(totalGhgEmission);

    if (tempData?.Scope3) {
      result.push({ ...tempData.Scope3, label: "Scope 3 Emission" });
    }

    // if (tempData?.groupGEmission) {
    //   Object.keys(tempData?.groupGEmission || {}).forEach((key) => {
    //     const data = tempData?.groupGEmission[key];
    //     const name = tempData?.groupGEmission[key].name || key;
    //     result.push({...data, label: name});
    //   });
    // }

    return result;
  }, [dashboardDetails?.charts?.aqi, year]);

  // Prepare emission data
  const revenueData = useMemo(() => {
    const filteredData =
      revenuePageData?.fieldData?.filter(
        (e) => e?.type === "emission" && e?.title
      ) || [];

    const revenueUnit =
      revenuePageData?.revenue?.find((e) => e?.type === "all")?.unit
        ?.shortName || "";

    // Define the groups from REVENUE_LABELS for energy
    const groups = Object.keys(REVENUE_LABELS.emission);

    // Create a map of groups with zero values for missing years
    const years = [year - 1, year];
    const dataMap = groups.reduce((acc, group) => {
      acc[group] = years.reduce((yearAcc, y) => {
        yearAcc[y] = 0; // Default to zero for each year
        return yearAcc;
      }, {});
      return acc;
    }, {});

    // Populate dataMap with actual values
    filteredData.forEach((ele) => {
      const title = ele?.title;
      const value = ele?.value || 0;
      const matchingGroup = Object.keys(REVENUE_LABELS.emission).find(
        (group) => REVENUE_LABELS.emission[group] === title
      );

      if (matchingGroup) {
        years.forEach((y) => {
          if (ele?.year.toString() === y.toString()) {
            dataMap[matchingGroup][y] = value;
          }
        });
      }
    });

    // Transform dataMap into the format required for charts
    return groups.map((group) => ({
      name: REVENUE_LABELS.emission[group],
      unit: revenueUnit,
      data: years.map((y) => dataMap[group][y]),
    }));
  }, [revenuePageData?.fieldData, revenuePageData?.revenue, year]);

  return (
    <>
      <div className="d-flex flex-column gap-3">
        <div
          id="editor-chart-emission-1"
          className="p-4 border chart-pop  rounded"
        >
          <div className="d-flex justify-content-end">
            <ChartImportButton editor={editor} id="editor-chart-emission-1" />
          </div>

          <ColumnChart
            data={
              columnChartData?.map(({ data, name }) => ({
                data: data.map((e) => getRoundedValue(e, 0)),
                name,
              })) || []
            }
            unit={dashboardDetails?.charts?.aqi?.unit}
            categories={[
              `FY${(year - 1).toString().slice(2)}`,
              `FY${year.toString().slice(2)}`,
            ]}
            yaxis={[]}
            download={false}
            heading="Emission"
          />
        </div>
        <div
          id="editor-chart-emission-2"
          className="p-4 border chart-pop  rounded"
        >
          <div className="d-flex justify-content-end">
            <ChartImportButton
              editor={editor}
              id="editor-chart-emission-2"
              style={{
                width: "400px",
              }}
            />
          </div>
          <DonutChart
            data={
              doughNutChartData.data.map((e) => getRoundedValue(e, 0)) || []
            }
            labels={doughNutChartData.labels || []}
            unit={dashboardDetails?.charts?.aqi?.unit}
            height={450}
            position="right"
            download={false}
            heading="Emission"

          />
        </div>
      </div>
      <div className="row">
        {barChartsGroupData.length !== 0 &&
          barChartsGroupData?.map?.(({ label, data }, index) => (
            <div
              id={`editor-barchart-emission-${index}`}
              className="p-4 border chart-pop  rounded col-xl-6"
            >
              <div className="d-flex justify-content-end">
                <ChartImportButton
                  editor={editor}
                  id={`editor-barchart-emission-${index}`}
                />
              </div>
              <div className="">
                <BarChart
                  series={[{ data, name: label }] || []}
                  categories={[
                    `FY${(year - 1).toString().slice(2)}`,
                    `FY${year.toString().slice(2)}`,
                  ]}
                  unit={dashboardDetails?.charts?.aqi?.unit}
                  labels={label}
                  download={false}
                />
              </div>
            </div>
          ))}
        {revenueData.map(({ name, data, unit }, index) => (
          <div
            id={`editor-revenue-barchart-emission-${index}`}
            className="p-4 border chart-pop  rounded col-xl-6"
          >
            {" "}
            <div className="d-flex justify-content-end">
              <ChartImportButton
                editor={editor}
                id={`editor-revenue-barchart-emission-${index}`}
              />
            </div>
            <div className="justify-content-end">
              <BarChart
                series={[{ data, name }] || []}
                categories={[
                  `FY${(year - 1).toString().slice(2)}`,
                  `FY${year.toString().slice(2)}`,
                ]}
                unit={dashboardDetails?.charts?.aqi?.unit + "/" + unit}
                labels={name}
                download={false}

              />
            </div>
          </div>
        ))}
      </div>
    </>
  );
});
// const BiodiversityCharts = observer(({ editor }) => {
//    
//   const {
//     dashboardStore: { dashboardDetails },
//   } = useStore();
//   const columnChartData = useMemo(() => {
//     const tempData = dashboardDetails?.charts?.biodiversity;
//     const result = ["A", "B1"].map((group, index) => {
//       return {
//         name: [
//           "Total number of species affected(Water bodies & Habitats)",
//           "Total number of IUCN Red List species and national conservation list species with habitats in areas affected by the operations of the organization, by level of extinction risk",
//         ][index],
//         data: [year - 1, year]?.map(
//           (year) =>
//             tempData?.[year]?.reduce((acc, curr) => ({ ...acc, ...curr }), {})[
//               group
//             ]?.prevValue || 0
//         ),
//         target: [year - 1, year]?.map(
//           (year) =>
//             tempData?.[year]?.reduce((acc, curr) => ({ ...acc, ...curr }), {})[
//               group
//             ]?.target || 0
//         ),
//       };
//     });
//     return result;
//   }, [dashboardDetails?.charts?.biodiversity, year]);
//   const doughNutChartData = useMemo(() => {
//     const tempData = dashboardDetails?.charts?.biodiversity?.[year];
//     const result = ["A", "B1"].map(
//       (group) =>
//         tempData?.reduce((acc, curr) => ({ ...acc, ...curr }), {})?.[group]
//           ?.prevValue || 0
//     );
//     const labels = [
//       "Total number of species affected(Water bodies & Habitats)",
//       "Total number of IUCN Red List species and national conservation list species with habitats in areas affected by the operations of the organization, by level of extinction risk",
//     ];
//     return { data: result || [], labels };
//   }, [dashboardDetails?.charts?.biodiversity, year]);
//   return (
//     <>
//      <div className=" d-flex  gap-5 mt-5  flex-column">
//         <div
//           id="editor-chart-biodiversity-1"
//           className="p-4 border chart-pop  rounded "
//         >
//           <div className="d-flex justify-content-end">
//             <ChartImportButton editor={editor} id="editor-chart-biodiversity-1" />
//           </div>
//           <ColumnChart
//             data={
//               columnChartData?.map(({ data, name }) => ({
//                 data: data.map((e) => getRoundedValue(e, 0)),
//                 name,
//               })) || []
//             }
//             unit={dashboardDetails?.charts?.biodiversity?.unit}
//             categories={[
//               `FY${(year - 1).toString().slice(2)}`,
//               `FY${year.toString().slice(2)}`,
//             ]}
//             yaxis={
//                []
//             }
//           />
//         </div>

//         <div
//           id="editor-chart-biodiversity-2"
//           className="p-4 border chart-pop  rounded "
//         >
//           <div className="d-flex justify-content-end">
//             <ChartImportButton editor={editor} id="editor-chart-biodiversity-2" />
//           </div>
//           <DonutChart
//             data={doughNutChartData.data || []}
//             labels={doughNutChartData.labels || []}
//           />
//         </div>
//       </div>
//     </>
//   );
// });

// All revenue chart
const RevenueCharts = observer(({ editor, year }) => {  
  const {
    companyStore: { fetchRevenueData, revenuePageData },
  } = useStore();

  useEffect(() => {
    fetchRevenueData({ year });
  }, [fetchRevenueData, year]);

  const chartData = useMemo(() => {
    return ["waste", "all"].map((ele) => {
      const filteredData = revenuePageData?.revenue?.filter(
        (e) => e?.type === ele
      );
      const unit = filteredData?.[0]?.unit?.shortName || ""; // Assuming the unit is consistent within each type
      return {
        name: ele === "waste" ? "Revenue due to Waste" : "Total Revenue",
        unit,
        data: [year - 1, year].map((f) => {
          return (
            filteredData?.find((e) => f.toString() === e?.year?.toString())
              ?.value || 0
          );
        }),
      };
    });
  }, [revenuePageData, year]);

  return (
    <>
      <div className="d-flex  gap-5 mt-5  flex-column">
        <div
          id="editor-chart-revenue-1"
          className="p-4 border chart-pop  rounded "
        >
          <div className="d-flex justify-content-end">
            <ChartImportButton editor={editor} id="editor-chart-revenue-1" />
          </div>
          <ColumnChart
            data={chartData}
            categories={[
              `FY${(year - 1).toString().slice(2)}`,
              `FY${year.toString().slice(2)}`,
            ]}
            yaxis={[]}
            unit={chartData.length > 0 ? chartData[0].unit : " "}
            download={false}
            heading ="Revenue"
          />
        </div>
      </div>
    </>
  );
});
const MonthlyParameter = observer(({ editor, year }) => {
  const {
    social: { fetchChartData, chartData },
  } = useStore();
  
 const data = chartData?.filter((e) => e.kpiName === "Monthly Parameter")
 useEffect(() => {
  fetchChartData({ year});
}, [fetchChartData, year]);
 const categories = [
    `FY${(year - 1).toString().slice(2)}`,
    `FY${year.toString().slice(2)}`,
  ];
  const totalSum = useCallback(
    (data) => data?.reduce((acc, curr) => curr.value + acc, 0) || 0,
    []
  );
  const months = [
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
    "January",
    "February",
    "March",
  ];
  const current = data?.filter(
    (e) =>
      months.map((month, index) => {
        const currentYear = index < 9 ? Number(year) : Number(year) + 1;
        return e?.month === month && e?.year === currentYear;
      }).some((element) => element)
  );
  const previous = data?.filter(
    (e) =>
      months.map((month, index) => {
        const currentYear = index < 9 ? Number(year) - 1 : Number(year);
        return e?.month === month && e?.year === currentYear;
      }).some((element) => element)
  );

  const employeeTurnoverFirst = [
    {
      name: "Male",
      group: "first",
      data: [
        totalSum(filterData(previous, "fieldGroup", "A_A_A")),
        totalSum(filterData(current, "fieldGroup", "A_A_A")),
      ],
    },
    {
      name: "Female",
      group: "first",
      data: [
        totalSum(filterData(previous, "fieldGroup", "A_A_B")),
        totalSum(filterData(current, "fieldGroup", "A_A_B")),
      ],
    },
    {
      name: "Local Employee",
      group: "second",
      data: [
        totalSum(filterData(previous, "fieldGroup", "A_A_C")),
        totalSum(filterData(current, "fieldGroup", "A_A_C")),
      ],
    },

    {
      name: "OutSourced Employee",
      group: "second",
      data: [
        totalSum(filterData(previous, "fieldGroup", "A_A_D")),
        totalSum(filterData(current, "fieldGroup", "A_A_D")),
      ],
    },
  ];
  const ohcFirstChartData = [
    "Number of Employees whose Work/Workplace is controlled by the Organisation.",
    "Number of Employees Covered by the OHS System and internally audited.",
    "Number of Employees covered by the OHS System, Audited or Certified by an External party.",
    "Number of Employees Covered by the OHS System",
    "Number of Workers Excluded.",
  ].map((e, i) => ({
    name: e,
    group: i === 4 ? "second" : "first",
    data: [
      totalSum(filterData(previous, "fieldName", e)),
      totalSum(filterData(current, "fieldName", e)),
    ],
  }));
  const ohcSecondChartData = [
    {
      label: "Fatalities (as a result of work-related injury)",
      value:
        "The number and rate of fatalities as a result of work-related injury.",
      group: "C_A",
    },
    {
      value:
        "The number and rate of high-consequence work-related injuries (excluding fatalities)",
      group: "C_A",
      label: "High-consequence work-related injuries (excluding fatalities",
    },
    {
      value: "The number and rate of recordable work-related injuries",
      label: "Recordable work-related injuries",
      group: "C_A",
    },
    {
      value: "LTIFR",
      label: "LTIFR",
      group: "C_A",
    },
    {
      value: "The number of hours worked.",
      label: "Man hours worked",
      group: "C_A",
    },
  ].map((e) => ({
    name: e.group === "C_A" ? e.label + " " : e.label,
    group: e.group,
    data: [
      totalSum(
        previous?.filter(
          (f) => f?.fieldName === e.value && f?.fieldGroup === e.group
        )
      ),
      totalSum(
        current?.filter(
          (f) => f?.fieldName === e.value && f?.fieldGroup === e.group
        )
      ),
    ],
  }));
  const ohcThirdChartData = [
    {
      value:
        "The number and rate of fatalities as a result of work-related injury",
      label: "Fatalities (as a result of work-related injury)",
      group: "C_B",
    },
    {
      value:
        "The number and rate of high-consequence work-related injuries (excluding fatalities)",
      group: "C_B",
      label: "High-consequence work-related injuries (excluding fatalities",
    },
    {
      value: "The number and rate of recordable work-related injuries",
      group: "C_B",
      label: "Recordable work-related injuries",
    },
    {
      value: "LTIFR",
      label: "LTIFR",
      group: "C_B",
    },
    {
      value: "The number of hours worked.",
      group: "C_B",
      label: "Man hours worked",
    },
  ].map((e) => ({
    name: e.group === "C_A" ? e.label + " " : e.label,
    group: e.group,
    data: [
      totalSum(
        previous?.filter(
          (f) => f?.fieldName === e.value && f?.fieldGroup === e.group
        )
      ),
      totalSum(
        current?.filter(
          (f) => f?.fieldName === e.value && f?.fieldGroup === e.group
        )
      ),
    ],
  }));

  return (
    <>
    <div className="m-4">
      <h2 className="text-muted">
          SOCIAL
      </h2>
    </div>
    <div className="" id="editor-social-EmployeeHireGraph">
      <div className="card mb-0 h-100">
        <div className="card-body">
          <div className="d-flex justify-content-end">
            <ChartImportButton
              editor={editor}
              id="editor-social-EmployeeHireGraph"
            />
          </div>
          <div className="col-xl-12 d-flex flex-wrap ">
            <div className="col-xl-12 border-end  ">
              <GroupStackedChart categories={categories} series={employeeTurnoverFirst} download={false}
                heading="New Employee Hires And employee turnover (in Numbers)"
                subHeading="Total number and rate of new employee hires during the reporting period"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="" id="editor-social-ohcFirstChartData">
      <div className="card mb-0 h-100">
        <div className="card-body">
          <div className="d-flex justify-content-end">
            <ChartImportButton
              editor={editor}
              id="editor-social-ohcFirstChartData"
            />
          </div>
          <div className="col-xl-12 d-flex flex-wrap ">
            <div className="col-xl-12 border-end  ">
              <GroupStackedChart categories={categories} series={ohcFirstChartData} download={false}
               heading="Number of employees covered by the OHS System"
                subHeading="Workers covered by an occupational health and safety management system"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="" id="editor-social-ohcSecondChartData">
      <div className="card mb-0 h-100">
        <div className="card-body">
          <div className="d-flex justify-content-end">
            <ChartImportButton
              editor={editor}
              id="editor-social-ohcSecondChartData"
            />
          </div>
          <div className="col-xl-12 d-flex flex-wrap ">
            <div className="col-xl-12 border-end  ">
              <GroupStackedChart categories={categories} series={ohcSecondChartData} download={false}
               heading = "Work Related Injuries."
               subHeading="For all employees:"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="" id="editor-social-ohcThirdChartData">
      <div className="card mb-0 h-100">
        <div className="card-body">
          <div className="d-flex justify-content-end">
            <ChartImportButton
              editor={editor}
              id="editor-social-ohcThirdChartData"
            />
          </div>
          <div className="col-xl-12 d-flex flex-wrap ">
            <div className="col-xl-12 border-end  ">
              <GroupStackedChart categories={categories} series={ohcThirdChartData} download={false}
                 heading = " "
                 subHeading="For all workers:"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
});

const  CustomerAndPrivacyChart = observer(({ editor, year }) => {
  const {
    social: { fetchChartData, chartData },
  } = useStore();
  
 const data = chartData?.filter((e) => e.kpiName === "Customer Privacy")
 useEffect(() => {
  fetchChartData({ year});
}, [fetchChartData, year]);
 const categories = [
    `FY${(year - 1).toString().slice(2)}`,
    `FY${year.toString().slice(2)}`,
  ];
  const totalSum = useCallback(
    (data) => data?.reduce((acc, curr) => curr.value + acc, 0) || 0,
    []
  );
  const current = filterData(data, "year", year);
  const previous = filterData(data, "year", year - 1);
  const customerPrivacyData = [
    {
      label:
        "Complaints received from outside parties and substantiated by the organization",
      value:
        "Complaints received from outside parties and substantiated by the organization",
      group: "A",
    },
    {
      label: "Complaints from regulatory bodies",
      value: "Complaints from regulatory bodies",
      group: "A",
    },
    {
      label:
        "Total number of identified leaks, thefts, or losses of customer data",
      value:
        "Total number of identified leaks, thefts, or losses of customer data",
      group: "B",
    },
  ].map((e) => ({
    name: e.label,
    group: e.group,
    data: [
      totalSum(filterData(previous, "fieldName", e.value)),
      totalSum(filterData(current, "fieldName", e.value)),
    ],
  }));

  return (
    <div className="">
      <div className="card mb-0 h-100" id="editor-social-CustomerAndPrivacyChart">
        <div className="card-body">
          <div className="d-flex justify-content-end">
            <ChartImportButton
              editor={editor}
              id="editor-social-CustomerAndPrivacyChart"
            />
          </div>
          <div>
            <GroupStackedChart categories={categories} series={customerPrivacyData} download={false} 
              heading="Customer Privacy (in Numbers)"
            />
          </div>
        </div>
      </div>
    </div>
  );

});
const GovernanceChart = observer(({ editor, year }) => {
  const {
    governance: { fetchDashboard, dashboard },
  } = useStore();
   
  useEffect(() => {
    fetchDashboard({ year });
  }, [fetchDashboard, year]);

  const specificFieldsForPieChart = useMemo(
    () => [
      "Existence of data privacy and cybersecurity policies.",
      "Existence and effectiveness of a risk management framework.",

    ],
    []
  );
  const getDonutChartData = useCallback(
    ({ group, year }) => {
      const current = dashboard?.[Number(year).toString()]?.[group] || [];
      const data =
        current
          ?.filter((ele) => !specificFieldsForPieChart.includes(ele.fieldName)) // Filter out specific fields
          ?.map((ele) => Number(ele.value) || 0) || [];
      const labels =
        current
          ?.filter((ele) => !specificFieldsForPieChart.includes(ele.fieldName)) // Filter out specific fields
          ?.map((ele) => ele.fieldName) || [];
      return { data, labels };
    },
    [dashboard, specificFieldsForPieChart]
  );
  
  return (
    <>
     <div className="m-4">
      <h2 className="text-muted">
        Governance
      </h2>
    </div>
      {["A", "G","B","I"].map((group) => (
        <div className="card p-4"key={group} id={`editor-governance-${group}`}>
           <div className="d-flex justify-content-end">
            <ChartImportButton
              editor={editor}
              id={`editor-governance-${group}`}
            />
          </div>
          
          <div className="donut">
            <DonutChart
              height={512}
              data={getDonutChartData({ group, year }).data}
              labels={getDonutChartData({ group, year }).labels}
              // position="right"
              download={false}
              heading = {GOVERNANCE_STATIC_GROUPS.GovernanceKpis[group]}
            />
          </div>
        </div>
      ))}
    </>
  );
});



export default observer(Charts);
